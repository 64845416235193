(function ($, w, undefined) {
    function PrimaryNav(args) {
        this.defaults = {
            container: '',
            btnAccordion: ''
        };

        this.options = $.extend(true, {}, this.defaults, args);

        this.activeItem = null;
        this.$container = $(this.options.container);
        this.$btnAccordion = this.parent(this.options.btnAccordion);

        this.bindEvents();
    }

    /**
     * Returns a jQuery element that is a child of the parent element
     * @param selector
     * @returns {*|HTMLElement}
     */
    PrimaryNav.prototype.parent = function (selector) {
        return $(selector, this.$container);
    };


    PrimaryNav.prototype.openAccordion = function (targetElem) {
        if (targetElem != null && 'undefined') {
            targetElem.addClass('active');
            this.activeItem = targetElem;
        }
    };

    PrimaryNav.prototype.closeAccordion = function (targetElem) {
        if (targetElem != null && 'undefined'){
            targetElem.removeClass('active');
        }
    };

    PrimaryNav.prototype.bindEvents = function () {

        var self = this;

        this.$btnAccordion.click(function () {

            var $targetItem = $(this).parent('li');
            var $activeItem = self.activeItem;

            if ($activeItem != null && 'undefined'){
                self.closeAccordion($activeItem);
            }

            if ($targetItem.is($activeItem)){
                self.activeItem = null;
            } else {
                self.openAccordion($targetItem);
            }
        });
    };

    w.RFDS = w.RFDS || {};
    w.RFDS.Components = w.RFDS.Components || {};
    w.RFDS.Components.PrimaryNav = PrimaryNav;

})(jQuery, window);


