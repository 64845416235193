(function($, w, undefined) {
    function Video(args) {
        this.defaults = {
            container: '.module-video',
            videoPlayer: null,
            playerId: '',
            playButton: '.play-button',
            overlay: '.overlay'
        };

        this.options = $.extend(true, {}, this.defaults, args);
        this.$container = $(this.options.container);

        this.$playButton = $(this.parent(this.options.playButton));
        this.$overlay = $(this.parent(this.options.overlay));
        
        this.initPlayer();
    };

    Video.prototype.parent = function (selector) {
        return $(selector, this.$container);
    };

    Video.prototype.initPlayer = function () {
        var self = this;
        
        this.videoPlayer = new Plyr(this.options.playerId, {
            controls: [],
            settings: [],
            volume: 1,
            muted: false,
            fullscreen: {
                enabled: false
            },
            loop: {
                active: true
            },
            clickToPlay: true
        });


        this.videoPlayer.on('pause', function() {
            self.$overlay.show(); 
        });

        this.$playButton.click(function (e) {
            e.preventDefault();

            playVideo();
            self.$overlay.hide(); 
        });

        async function playVideo() {
            try {
              await self.videoPlayer.play();
              console.log("play button pressed");
              //playButton.classList.add("playing");
            } catch (err) {
              //playButton.classList.remove("playing");
            }
        }
    };

    w.RFDS = w.RFDS || {};
    w.RFDS.Modules = w.RFDS.Modules || {};
    w.RFDS.Modules.Video = Video;
})(jQuery, window);
