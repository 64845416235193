(function($, w, undefined) {
    function HeroSlider(args) {
        this.defaults = {
            container: '',
            btnNext: '',
            btnPrevious: '',
            pagination: '',
            defaultSpeed: 5000,
            showVideo: true,
            allowTouch: false,
            animationEffect: 'slide'
        };

        this.options = $.extend(true, {}, this.defaults, args);

        this.$container = $(this.options.container);
        this.$pagination = $(this.options.pagination);

        this.swiper = null;
        this.players = {};

        this.initSwiper();
        this.setEvents();
        this.eventSlideChangeStart(this.swiper);
        this.eventSlideChangeEnd(this.swiper);
    };

    HeroSlider.prototype.initSwiper = function (){
        var self = this;

        this.swiper = new Swiper(this.options.container, {
            direction: 'horizontal',
            loop: true,
            effect: this.options.animationEffect,
            allowTouchMove: this.options.allowTouch,

            navigation: {
                nextEl: this.options.btnNext,
                prevEl: this.options.btnPrevious
            },

            pagination: {
                el: this.options.pagination,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '"><span class="track"><span class="track-inner"></span></span></span>';
                }
            },

            on: {
                init: function () {
                    self.$container.addClass('ready');
                }
            }
        });
    };

    HeroSlider.prototype.eventSlideChangeStart = function(swiper) {
        var self = this;
        var currentIndex = swiper.activeIndex;
        var realIndex = swiper.realIndex; //Helpful when swiper loops
        var progressAnimationLength = this.options.defaultSpeed;

        // Clear the image timeout of the previous slide (if it exists of course)
        clearTimeout(this.nonVideoTimeout);

        // if this slide has a video in it.
        if($('video', swiper.slides[currentIndex]).length > 0 && this.options.showVideo === true) {
            if(typeof this.players[currentIndex] === 'undefined') {


                // initialise the player
                this.players[currentIndex] = new Plyr($('video', swiper.slides[currentIndex]),{
                    controls: [],
                    settings: [],
                    volume: 0,
                    muted: true,
                    fullscreen: {
                        enabled: false
                    },
                    clickToPlay: false
                });

                this.players[currentIndex].on('ended', function(event) {
                    swiper.slideNext();
                });

                this.players[currentIndex].on('playing', function(event) {
                    //Video duration is in seconds, convert to MS for easier and consistent CSS transitions
                    progressAnimationLength = Math.ceil(self.players[currentIndex].duration * 1000);
                    self.applyProgressAnimation(realIndex, progressAnimationLength)
                });
            }
        }

        else {
            var self = this;
            this.applyProgressAnimation(realIndex, progressAnimationLength);
            this.nonVideoTimeout = setTimeout(function() {
                swiper.slideNext();
            }, self.options.defaultSpeed);
        }
    };

    HeroSlider.prototype.eventSlideChangeEnd = function(swiper) {
        var currentIndex = swiper.activeIndex;
        if($('video', swiper.slides[currentIndex]).length > 0 && this.options.showVideo === true) {
            this.players[currentIndex].play();
            this.previousIndex = currentIndex;
        } else {
            for( var key in this.players ) {
                if(this.players.hasOwnProperty(key)) {
                    this.players[key].stop();
                }
            }
        }
    };

    HeroSlider.prototype.applyProgressAnimation = function(slideIndex, animationDuration){

        var self = this;

        /*
        Timeout is required to prevent 'is-animating' class applying immediately,
        thus preventing element from animating, as it jumps directly to end state
         */
        setTimeout(function(){
            var $prevBullet = self.$pagination.children().eq(slideIndex - 1);
            var $targetBullet = self.$pagination.children().eq(slideIndex);
            var $targetBulletInner = $targetBullet.children().find('.track-inner');

            $targetBulletInner.addClass('is-animating');
            $targetBulletInner.css('transition-duration', animationDuration +'ms');

            if ($prevBullet.length > 0 && (slideIndex - 1 >= 0 )){
                $prevBullet.addClass('has-animated');
            }
        }, 1);
    };

    HeroSlider.prototype.resetProgressAnimation = function(){
        this.$pagination.children('.swiper-pagination-bullet').each(function(){
            $(this).removeClass('has-animated');
            $(this).find('.track-inner').removeClass('is-animating');
            $(this).find('.track-inner').css('transition-duration', 0 +'ms');
        });
    };


    HeroSlider.prototype.setEvents = function () {
        var self = this;
        this.swiper.on('slideChangeTransitionStart', function() { self.eventSlideChangeStart(this) });
        this.swiper.on('slideChangeTransitionEnd', function() { self.eventSlideChangeEnd(this) });
        this.swiper.on('reachBeginning', function() { self.resetProgressAnimation() });
        this.swiper.on('reachEnd', function() { self.resetProgressAnimation() });
    };

    w.RFDS = w.RFDS || {};
    w.RFDS.Modules = w.RFDS.Modules || {};
    w.RFDS.Modules.HeroSlider = HeroSlider;
})(jQuery, window);
