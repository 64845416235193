(function ($, w, undefined) {
    function Form(args) {
        
        this.defaults = {
            container: '.module-form',
            checkboxContainer: '.umbraco-forms-field.checkbox',
            checkbox: 'input[type="checkbox"]',
            check: 'span.check',
            label: 'label'
        };

        this.options = $.extend(true, {}, this.defaults, args);
        this.$container = $(this.options.container);
        
        this.$check = $(this.parent(this.options.check));
        this.$checkboxes = $(this.parent(this.options.checkbox, this.options.checkboxContainer));

        this.bindEvents();
    }

    Form.prototype.parent = function (selector) {
        return $(selector, this.$container);
    };

    Form.prototype.parent = function(selector, parent) {
        return $(selector, parent);
    };

    Form.prototype.bindEvents = function() {
        var self = this;

        this.$check.click(function() {
            var $current = $(this);
            var $container = $current.closest(self.options.checkboxContainer);
            var $checkBox = $($container).find(self.options.checkbox);

            if ($checkBox.is(':checked')) {
                $checkBox.prop("checked", false);
            } else {
                $checkBox.prop("checked", true);
            }
        });

        // Add disabled class in the parent container to style label and checkbox inside it.
        this.$checkboxes.each(function() {
            var $current = $(this);
            var $container = $current.closest(self.options.checkboxContainer);

            if ($current.is(':disabled')) {
                $container.addClass('disabled');
            }
        });
    };

    w.RFDS = w.RFDS || {};
    w.RFDS.Modules = w.RFDS.Modules || {};
    w.RFDS.Modules.Form = Form;


})(jQuery, window);