(function ($, w, undefined) {
    function MasterHeader(args) {

        this.defaults = {
            container: '',
            primaryNav: '',
            btnOffCanvas: '',
            scrollOffset: 0
        };

        this.state = {
            navOpen: false
        };

        this.options = $.extend(true, {}, this.defaults, args);

        this.$container = $(this.options.container);
        this.$btnOffCanvas = this.parent(this.options.btnOffCanvas);
        this.$primaryNav = $(this.options.primaryNav);

        this.initWaypoint();
        this.detectAutoPin();
        this.bindEvents();

        //Debug ONLY
        // this.toggleNav();
    }

    /**
     * Returns a jQuery element that is a child of the parent element
     * @param selector
     * @returns {*|HTMLElement}
     */
    MasterHeader.prototype.parent = function (selector) {
        return $(selector, this.$container);
    };

    /*
     Interface events
     */
    MasterHeader.prototype.openPrimaryNav = function () {
        this.$container.addClass('nav-open');
        this.$primaryNav.addClass('nav-open');
        this.$btnOffCanvas.addClass('active');
        this.state.navOpen = true;
    };

    MasterHeader.prototype.closePrimaryNav = function () {
        this.$container.removeClass('nav-open');
        this.$primaryNav.removeClass('nav-open');
        this.$btnOffCanvas.removeClass('active');
        this.state.navOpen = false;
    };

    MasterHeader.prototype.toggleNav = function () {
        if (this.state.navOpen === true) {
            this.closePrimaryNav();
        } else {
            this.openPrimaryNav();
        }
    };

    MasterHeader.prototype.applyStickyState = function (on){
        if (on === true){
            this.$container.addClass('light');
        } else {
            this.$container.removeClass('light');
        }
    };


    MasterHeader.prototype.initWaypoint = function() {
        var self = this;
        var waypoint = new Waypoint({

            element: this.$container,
            offset: this.options.scrollOffset,

            handler: function(direction) {
                if (direction === 'down'){
                    self.applyStickyState(true);
                } else {
                    self.applyStickyState(false);
                }
            }
        })
    };

    MasterHeader.prototype.detectAutoPin = function(){

        var currentYPos = window.pageYOffset;
        var offSet = this.options.scrollOffset;

        if (offSet < 0 ){
            offset = Math.abs(offSet);
        }

        if (currentYPos >= offset ){
            this.applyStickyState(true);
        }
    };

    MasterHeader.prototype.bindEvents = function () {

        var self = this;
        this.$btnOffCanvas.click(function () {
            self.toggleNav();
        });
    };

    w.RFDS = w.RFDS || {};
    w.RFDS.Components = w.RFDS.Components || {};
    w.RFDS.Components.MasterHeader = MasterHeader;

})(jQuery, window);


