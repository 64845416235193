(function ($, w, undefined) {
    function CountDownPopup(args) {
        this.defaults = {
            container: '',
            eventDate: '',
            btnClose: '',
            btnCTA: '.btn-primary',
            countDown: '',
            daysValue: '',
            hoursValue: '',
            minsValue: '',
            cookieName: 'popupModal',
            cookieTimeout: 60
        };

        this.options = $.extend(true, {}, this.defaults, args);

        this.$container = $(this.options.container);
        this.$btnClose = this.parent(this.options.btnClose);
        this.$btnCTA = this.parent(this.options.btnCTA);
        this.$countDown = this.parent(this.options.countDown);
        this.$monthsValue = this.parent(this.options.monthsValue);
        this.$daysValue = this.parent(this.options.daysValue);
        this.$hoursValue = this.parent(this.options.hoursValue);
        this.$minutesValue = this.parent(this.options.minsValue);

        this.bindEvents();
        if (!this.cookieExists()) {
            this.$container.modal('show');
        }
    }


    CountDownPopup.prototype.parent = function (selector) {
        return $(selector, this.$container);
    };

    CountDownPopup.prototype.bindEvents = function () {
        var self = this;

        this.$btnClose.click(function () {
            self.$container.modal('hide');
        });

        this.$btnCTA.click(function () {
            self.saveCookie();
            return true;
        });

        this.$container.on('hidden.bs.modal', this.saveCookie.bind(this));


        this.$countDown.countdown(this.options.eventDate, function (event) {
            self.$monthsValue.text(event.strftime('%m'));
            self.$daysValue.text(event.strftime('%n'));
            self.$hoursValue.text(event.strftime('%H'));
            self.$minutesValue.text(event.strftime('%M'));
        });


    };

    CountDownPopup.prototype.saveCookie = function () {
        var timeoutDays = this.options.cookieTimeout / (24 * 60);
        Cookies.set(this.options.cookieName, true, { expires: timeoutDays });
    };

    CountDownPopup.prototype.cookieExists = function () {
        console.log('cookieExists');
        var cookie = Cookies.get(this.options.cookieName);
        return typeof cookie !== 'undefined';


    };

    w.RFDS = w.RFDS || {};
    w.RFDS.Components = w.RFDS.Components || {};
    w.RFDS.Components.CountDownPopup = CountDownPopup;

})(jQuery, window);